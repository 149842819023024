import React, { FC, useContext, useEffect, useState } from 'react';
import { CustomerContext } from '../context/CustomerContext';
import { GaContext } from '../context/GaContext';

import { useDataLayerPush } from '../hooks/useDataLayerPush';
import { pixieEvent } from '../utils/pixieEvent';

export interface DataLayerWrapperProps {
  children: any;
  data: any;
  serverData: any;
  location: any;
}

export const DataLayerWrapper: FC<DataLayerWrapperProps> = ({ children, data, serverData, location }) => {
  const { dataLayerPush } = useDataLayerPush();
  const { routeData } = useContext(GaContext);
  const { quote, basket } = useContext(CustomerContext);
  const currentPath = routeData?.path ? routeData?.path : '';

  const activeQuoteId = quote?.Id;
  const allQuoteIds = basket?.Quotes?.map((quote) => quote.Id).reverse() || [];
  const activeHorseIndex = allQuoteIds?.findIndex((id) => id === activeQuoteId);
  let horserNumber = activeHorseIndex && activeHorseIndex >= 0 ? activeHorseIndex + 1 : 1;

  if (location?.state?.multiHorse) {
    horserNumber = allQuoteIds?.length > 1 ? allQuoteIds.length + 1 : 2;
  }

  // console.log('GA DATA');
  // console.group();
  // console.log(allQuoteIds);
  // console.log('multihorse', location?.state?.multiHorse);
  // console.log('number of quotes', allQuoteIds?.length);
  // console.log('activeHorseIndex', activeHorseIndex);
  // console.log('horserNumber', horserNumber);
  // console.groupEnd();
  // Handle route change
  useEffect(() => {
    const pageData = {
      event: 'gatsby-route-change',
      virtual_page_path: currentPath,
      funnel_stage: routeData?.stage,
      funnel_step: routeData?.step,
      horse_number: horserNumber,
    };

    if (currentPath) {
      dataLayerPush({
        ...pageData,
      });
      pixieEvent('PageView', null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);
  return <>{children}</>;
};
