import React from 'react';
import { PageProps } from 'gatsby';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import GlobalStyles from '../styles/GlobalStyles';
import theme from '../styles/tokens';
import { CustomerContextProvider } from '../context/CustomerContext';
import { GaContextProvider } from '../context/GaContext';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { DataLayerWrapper } from '../components/DataLayerWrapper';
import { SITE_NAME } from '../const';

const queryClient = new QueryClient();

export const BaseLayout = ({ location, data, children, serverData }: PageProps) => (
  <>
    <QueryClientProvider client={queryClient}>
      <Helmet
        htmlAttributes={{
          lang: 'en-GB',
        }}
      >
        <title>{SITE_NAME}</title>
        <meta name="description" content={SITE_NAME} />
      </Helmet>
      <GlobalStyles />
      <CustomerContextProvider location={location}>
        <ThemeProvider theme={theme}>
          <GaContextProvider>
            <DataLayerWrapper data={data} serverData={serverData} location={location}>
              <Header />
              <main>{children}</main>
              <Footer />
            </DataLayerWrapper>
          </GaContextProvider>
        </ThemeProvider>
      </CustomerContextProvider>
    </QueryClientProvider>
  </>
);
