import React, { FC } from 'react';

export const Phone: FC = (props) => (
  <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.974 17.15a1.233 1.233 0 0 0-1.366.008l-2.135 1.404a1.235 1.235 0 0 1-1.513-.1c-.758-.653-1.978-1.752-3.039-2.923-1.06-1.172-2.056-2.518-2.648-3.355a1.602 1.602 0 0 1-.09-1.67l1.271-2.357a1.62 1.62 0 0 0 .005-1.512L5.327.738C5.023.167 4.42-.114 3.837.043 3.27.196 2.535.566 1.763 1.42-.65 4.084-1.934 8.58 7.147 18.604c9.08 10.023 13.152 8.609 15.567 5.942.774-.853 1.108-1.666 1.247-2.293.141-.643-.11-1.304-.626-1.638l-5.361-3.465Z"
      fill="#006954"
    />
  </svg>
);
