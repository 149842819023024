import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { FooterLogo } from './FooterLogo';
import { Section } from './Section';
import { Spacer } from './Spacer';
import SecureGlobalSign from './svg/SecureGlobalSign';
import { Typography } from './Typography';

interface FooterProps {}

const StyledSection = styled(Section)`
  ${({ theme: { space, media } }) => css`
    padding: ${space.xl} 0;
    @media (min-width: ${media.lg}) {
      padding: ${space.xxl} 0;
    }
  `};
`;

const StyledLogos = styled.div`
  ${({ theme: { space, media } }) => css`
    margin-top: ${space.xl};
    display: flex;
    align-items: center;
    gap: ${space.sm};
    @media (min-width: ${media.lg}) {
      margin-top: ${space.xxl};
    }
  `};
`;

export const Footer: FC<FooterProps> = () => (
  <footer>
    <StyledSection background="darkBlue">
      <Spacer>
        <Typography component="p" variant="labelRegular" color="white">
          Agria Pet Insurance Ltd is authorised and regulated by the Financial Conduct Authority, Financial Services
          Register Number 496160. Agria Pet Insurance Ltd is registered and incorporated in England and Wales with
          registered number 4258783. Registered office: First Floor, Blue Leanie, Walton Street, Aylesbury,
          Buckinghamshire, HP21 7QW.
        </Typography>

        <Typography component="p" variant="labelRegular" color="white">
          Agria Försäkring is the UK branch of Försäkringsaktiebolaget Agria (publ) who are registered in Sweden with
          Company Registration Number 516401-8003. Registered office: Box 703 06, 107 23 Stockholm, Sweden.
          Försäkringsaktiebolaget Agria (publ) is authorised and regulated by Finaninspektionen in the jurisdiction of
          Sweden. Authorised by the Prudential Regulation Authority. Subject to regulation by the Financial Conduct
          Authority and limited regulation by the Prudential Regulation Authority (Firm Reference Number 623469).
          Details about the extent of our regulation by the Prudential Regulation Authority are available from us on
          request.
        </Typography>
      </Spacer>
      <StyledLogos>
        <FooterLogo />
        <SecureGlobalSign />
      </StyledLogos>
    </StyledSection>
  </footer>
);

export default Footer;
